
export const paymentStatus:any = {
    none                : { label:"nessuno",                 color: "info",         icon: "it-card"},
    cancelled           : { label:"annullato",               color: "info",         icon: "it-card"},
    notPaid             : { label:"non pagato",              color: "error",        icon: "it-card"},
    processing          : { label:"in corso",                color: "warning",      icon: "it-card"},
    paid                : { label:"pagato",                  color: "success",      icon: "it-card"},
    error               : { label:"errore",                  color: "error",        icon: "it-card"}
}

export class Payment {

    id:number;
    iuv:String;
    reason:String;
    note:string;
    user:any;
    created:Date;

    amount:number;
    status:string;
    method:String;

    paymentDate?:Date;
    expireDate?:Date;
    cancelDate?:Date;

    get canBePaid() {
        return this.status == 'notPaid';
    } 
    get isPaid() {
        return this.status == 'paid';
    } 
    get isCanceled() {
        return this.status == 'cancelled';
    }

    constructor(data:any){

        this.id = data.id;
        this.iuv = data.iuv;
        this.reason = data.reason;
        this.note = data.note ?? "";
        this.user = data.user;
        this.created =      new Date(data.created);

        this.amount = data.position.amount;
        this.status = data.position.status;

        if(data.position.paymentDate){
            this.paymentDate = new Date(data.position.paymentDate);
        }
        if(data.position.expireDate){
            this.expireDate = new Date(data.position.expireDate);
        }
        if(data.canceled){
            this.cancelDate = new Date(data.canceled);
            this.status = "cancelled"
        }
        this.method = data.position.method;
    }
}
