import env from 'env';

export class RequestFile {
    position:number;

    id:number|undefined;
    name:string|undefined;
    extension:string|undefined;
    size:number|undefined;
    status:string;

    file:File|undefined;

    get filename() { 
        return (this.name ?? "loading...") + (this.extension ?? ""); 
    }


    constructor(data:any){
        this.position       = data.position ?? -1;

        this.id =           data.id;
        this.name =         data.name;
        this.extension =    data.extension;
        this.size =         data.size;

        this.file           = data.file;

        this.status =       data.status ?? "completed";
    }



    getUrl(sportellodigitale_config:any) {
        return sportellodigitale_config.backend_url + "api/files/" + this.id + "/download/";
    }
}