import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import './css/app.css';

import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';

import { Outlet} from 'react-router-dom';
import { Container } from 'design-react-kit';

import TopHeader from './pa_kit/components/top_header';
import Footer from './pa_kit/components/footer';


function App() {
  return (
    <div className="App">
      <TopHeader />
      <Container fluid className="p-0">
          <Outlet />
      </Container>
      <Footer />
    </div>
  );
}

export default App;
