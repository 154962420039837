
import { ServiceCategory } from "./service_category";
import { Module } from "../../modules/models/module";

export enum ServiceContentsSections {
    "description"       = "Descrizione breve", //obbligatorio
    "recipients"        = "A chi è rivolto", //obbligatorio
    "full_description"  = "Descrizione",
    "geo"               = "Copertura geografica",
    "howto"             = "Come fare", //obbligatorio
    "inputs"            = "Cosa serve", //obbligatorio
    "outputs"           = "Cosa si ottiene", //obbligatorio
    "timeline"          = "Tempi e scadenze", //obbligatorio
    "costs"             = "Costi",
    "linked_rocedures"  = "Procedure collegate all'esito",
    "access"            = "Accedi al servizio", //obbligatorio
    "constraints"       = "Vincoli",
    "other_cases"       = "Casi particolari",
    "more_info"         = "Ulteriori informazioni",
    "terms"             = "Condizioni di servizio", //obbligatorio
    "contacts"          = "Contatti", //obbligatorio
    "unit"              = "Unità Organizzativa responsabile", //obbligatorio
    "documents"         = "Documenti"
}
export enum ServiceContentsSectionsDataTag {
    "recipients"        = "service-addressed", //obbligatorio
    "description"       = "service-description", //obbligatorio
    "full_description"  = "service-extended-description", //obbligatorio
    "howto"             = "service-how-to", //obbligatorio
    "inputs"            = "service-needed", //obbligatorio
    "outputs"           = "service-achieved", //obbligatorio
    "timeline"          = "service-calendar-text", //obbligatorio
}

export class Service {

    //SERVICE
    id:string;
    slug:string;
    name:string;
    description:string;
    tags:string[];

    completion_days:number;

    contents:any;
    modules:any;

    category:ServiceCategory | null;

    constructor(data:any){


        this.id             = data.id;
        this.slug           = data.slug;
        this.name           = data.name ;
        this.description    = data.description;
        this.tags           = data.tags_list;
        this.contents       = data.contents;
        this.modules        = data.modules ? data.modules.map((module:any) => new Module(module)) : null;

        this.completion_days = data.completion_days;

        this.category       = data.category ? new ServiceCategory(data.category) : null;
    }
}