export enum ModuleStepStatus {
    Uncompleted,
    Completed,
    Error,
}

export class ModuleStep {

    label:string;
    child:any;

    status:ModuleStepStatus = ModuleStepStatus.Uncompleted;

    constructor(data: any){
        this.label   = data.label;
        this.child   = data.child;
    }
}