/* eslint-disable array-callback-return */
// @ts-nocheck
import { useContext, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from './app/components/loading';

import './css/index.css';
import App from './App';
import './css/styles.css';

import { ActaAuthContext } from "./pa_kit/auth";


const AppRoutes = () => {

    const auth = useContext(ActaAuthContext);

    const theme = auth.theme;  

    const defaultTheme = {
        "--color-primary": theme.primary_color,
        "--color-secondary": theme.secondary_color,
        "--color-tertiary": theme.tertiary_color,
        "--color-quaternary": theme.quaternary_color,
    }    

    const applyTheme = (theme) => {
        Object.keys(theme).map((key) => {
            const value = theme[key];
            document.documentElement.style.setProperty(key, value);
        });
    };
    
    applyTheme(defaultTheme);

    //const localSettings = auth.localSettings;
    //const user = auth.user;


    const ModuleView = lazy(() => import('./app/modules/components/module'));
    const ModulesListView = lazy(() => import('./app/modules/components/list'));
    const RequestsListMineView = lazy(() => import('./app/requests/components/list_mine'));
    const RequestView = lazy(() => import('./app/requests/components/request'));
    const ServicesListView = lazy(() => import('./app/services/components/services_list'));
    const ServiceView = lazy(() => import('./app/services/components/service'));
    const PaymentResultView = lazy(() => import('./app/payments/components/payment_result'));
    const RequestsListView = lazy(() => import('./app/requests/components/list'));
    const LegalNotesiew = lazy(() => import('./app/policies/components/legal:notes'));
    const PrivacyPolicyView = lazy(() => import('./app/policies/components/provacy_policy'));
    const RequestsListWaitingProtocolView = lazy(() => import('./app/requests/components/list_waiting_protocol'));
    
    if(auth.isLoading) return <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Loading /></div>;
    return <>
        <BrowserRouter>
            <Suspense fallback={<div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Loading /></div>}>
              <Routes>
                <Route path="/" element={<App />} >

                  <Route path="" element={<ServicesListView />} />
                  <Route path="services">
                    <Route path="" element={<ServicesListView />} />
                    <Route path=":slug" element={<ServicesListView />} />
                  </Route>
                  <Route path="service">
                    <Route path=":slug" element={<ServiceView />} />
                  </Route>
                  <Route path="topics">
                    {/*
                    <Route path="" element={<ServiceTopicsListView />} />
                    */}
                    <Route path=":slug" element={<ServicesListView />} />
                  </Route>
                  <Route path="module">
                    <Route path="new" element={<div>NEW MODULE</div>} />
                    <Route path=":moduleId" element={<ModuleView />} />
                  </Route>
                  <Route path="modules">
                    <Route path="" element={<ModulesListView />} />
                    <Route path=":categorySlug" element={<ModulesListView />} />
                  </Route>
                  <Route path="request">
                    <Route path="new/:requestId" element={<div>NEW request</div>} />
                    <Route path=":requestId/payments/:paymentOutcome" element={<RequestView />} />
                    <Route path=":requestId" element={<RequestView />} />
                  </Route>
                  <Route path="requests">
                    <Route path="" element={<RequestsListMineView />} />
                  </Route>
                  <Route path="manage">
                    <Route path="requests">
                      <Route path="" element={<RequestsListView />} />
                      <Route path="waiting-protocol" element={<RequestsListWaitingProtocolView />} />
                    </Route>
                  </Route>
                  <Route path="payments">
                    <Route path="result" element={<PaymentResultView />} />
                  </Route>


                  <Route path="note-legali" element={<LegalNotesiew />} />
                  <Route path="privacy-policy" element={<PrivacyPolicyView />} />
                </Route>
              </Routes>
            </Suspense>
        </BrowserRouter>
    </>;
}

export default AppRoutes;

        
        