import { Service } from "./service";

export class ServiceCategory {

    //SERVICE
    id:string;
    slug:string;
    name:string;
    description:string;

    services:Service[];

    constructor(data:any){

        this.id = data.id;
        this.slug = data.slug;
        this.name = data.name ;
        this.description = data.description;

        this.services = data.services ? data.services.map((serviceData:any) => new Service(serviceData)) : [];
    }
}