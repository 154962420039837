import { Spinner } from "design-react-kit"

const Loading = (props:any) => {

    return(
        <div className="p-4 w-full text-center">
            <div  className="d-flex flex-column justify-content-center align-items-center">
                <Spinner active />
            </div>
        </div>
    );
}
export default Loading;