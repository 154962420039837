import { useContext, useEffect, useState } from "react";
import { ModuleStep, ModuleStepStatus } from "./module_step";
import ApiService from '../../../services/api'
import { Service } from "../../services/models/service";
import { ActaAuthContext } from "pa_kit/auth";
import { Request } from "app/requests/models/request";

export class Module {

    //MODULE
    id:string;
    name:string;
    description:string;
    steps:ModuleStep[];
    service:Service|null;

    messageOnSubmit:string|null;

    defaultPayments:Map<string,any>[]|null;
    requiredPayments:Map<string,any>[]|null;

    //RESPONSE
    isEditable:boolean = true;
    values:any;

    //UTILS
    activeStepIndex:number = 0;

    constructor(data:any){
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.service = data.service ? new Service(data.service) : null;
        this.steps = data.fieldset?.map((data:any) => new ModuleStep(data)) ?? [];

        this.messageOnSubmit = data.message_on_submit;

        this.defaultPayments = data.default_payments;
        this.requiredPayments = data.required_payments;
    }
}


export const useModule = (uuid:string|any, request?:Request) => {
    const auth = useContext<any>(ActaAuthContext);
    const user = auth.user;

    const [module, _setModule]       = useState(new Module({}));
    const [isLoading, _setIsLoading] = useState(true);

    useEffect(() => {
        fetchModule();
    },[]);

    
    const setModule = (module:Module) => {
        _setModule(module);
    }
    const setIsLoading = (isLoading:boolean) => {
        _setIsLoading(isLoading);
    }

    const fetchModule = async () => {
        var module;
        switch(typeof uuid){
            case "object": 
                module = new Module(uuid);
                break;
            case "string": 
                module = await ApiService.getModule(uuid);
                break;
            default:
                module = new Module({});
                break;
        }
        if(request?.values){
            module.values = request.values;
            module.isEditable = request.isEditable;
        }
        setModule(module);
        setIsLoading(false);
    }

    const getValue = (id:string, userDataField?:string):any => {
        
        //if(userDataField) return user.data[userDataField];
        
        if(!module.isEditable && module.values !== undefined) return module.values[id];

        if(user.getAttribute(userDataField) != null && user.getAttribute(userDataField) != ''){
            //module.values[id] = user.getAttribute(userDataField);
            setValue(id, user.getAttribute(userDataField));
            return user.getAttribute(userDataField);
        }
        
        if(module.values != null && module.values[id] != null) return module.values[id];

        if(user.getAttribute(id) != null && user.getAttribute(id) != ''){
            //module.values[id] = user.getAttribute(id);
            setValue(id, user.getAttribute(id));
            return user.getAttribute(id);
        }

        return undefined;
    }
    const hasUserDataFieldValue = (userDataField?:string):any => {
        if(userDataField == null) return false;
        return user.getAttribute(userDataField) != null && user.getAttribute(userDataField) != '';
    }
    const setValue = (id:string, value:any):void => {
        let values =  module.values ?? {};
        if(value !== values[id]){
            values[id] = value;
            setModule({...module, 
                values: values,
            });
            setActiveStepStatus(ModuleStepStatus.Uncompleted, false);
        }
    }
    const validate = (element:any, ignoreStepStatus:boolean = false):any => {
        if(hasUserDataFieldValue(element.userDataField)) return [false, null];
        if(!ignoreStepStatus && activeStep.status === ModuleStepStatus.Uncompleted) return [false, null];
        let value = getValue(element.id);
        
        if(element.isRequired && (value === undefined || value === "" || value === false || value === null)){
            return [true, "La compilazione di questo campo è obbligatoria"];
        }

        if(element.needsMore && value === true ){
            let motreValue = getValue(element.id+"_more");
            if(motreValue === undefined || motreValue === "" || motreValue === false || motreValue === null){
                return [true, null];
            }
        }

        if(element.class === "RadioFieldFormComponent" && element.values != null){
            element.values.forEach((choice:any, index:number) => {
                if(choice.needsMore && value === choice.value ){
                    let motreValue = getValue("choice_" + element.id + "_" + index + "_more");
                    if(motreValue === undefined || motreValue === "" || motreValue === false || motreValue === null){
                        return [true, null];
                    }
                }
            });
        }


        return [false, null];
    }

    const activeStep:ModuleStep = module.steps ? module.steps[module.activeStepIndex] : new ModuleStep({});

    const _validateAll = (element:any):boolean => {
        let [hasErrors, errorMessage] = validate(element, true);
        let isValid = !hasErrors;

        if(element.child){
            isValid = isValid && _validateAll(element.child);
        }
        if(element.children){
            for(let child of element.children){
                isValid = isValid && _validateAll(child);
            }
        }
        return isValid;
    }
    const setActiveStep = (index:number):void => {
        setModule({...module, 
            activeStepIndex: index,
        });
    }
    const setActiveStepStatus = (status:ModuleStepStatus, checkValidation:boolean = true):boolean => {
        let isStepValid = !checkValidation || _validateAll(activeStep.child);
        
        _setModule(prevModule => {
            let module = { ...prevModule };
            module.steps[module.activeStepIndex].status = isStepValid ? status : ModuleStepStatus.Error;
            return module;
        });

        return isStepValid;
    }
    const resetStepsStatus = ():void => {
        _setModule(prevModule => {
            let module = { ...prevModule };
            for(let i=0; i< module.steps.length; i++){
                module.steps[i].status = ModuleStepStatus.Uncompleted;
            }
            return module;
        });
    }

    const totalSteps:number = module.steps ? module.steps.length : 0;
    const isLastStep:boolean = module.activeStepIndex === totalSteps - 1;
    const isCompleted = ():boolean => {
        for(let step of module.steps){
            if(step.status !== ModuleStepStatus.Completed) return false;
        }
        return true;
    };
    
    const save = async ():Promise<string> => {
        var response = await ApiService.saveRequest(null, module, request);
        return response.id;
        //navigate("/request/" + response.id);
    }
    const saveDraft = async ():Promise<string> => {
        var response = await ApiService.saveRequest("draft", module, request);
        return response.id;
        //navigate("/request/" + response.id);
    }
  
    return {
        module,
        setModule,

        fetchModule,

        getValue,
        hasUserDataFieldValue,
        setValue,
        validate,

        activeStep,
        setActiveStep,
        setActiveStepStatus,
        resetStepsStatus,
        totalSteps,
        isLastStep,
        isCompleted,

        save,
        saveDraft,

        isLoading,
        setIsLoading,
    };
}

export const useModuleDefaultValue = {
    module: new Module({}),
    setModule: (module:Module) => {},

    fetchModule: ()=>{},

    getValue: (id:string, userDataField?:string):any => {},
    hasUserDataFieldValue: (userDataField:string):any => {},
    setValue: (id:string, value:any) => {},
    validate: (element:any):any => {},

    activeStep: new ModuleStep({}),
    setActiveStep: (index:number) => {},
    setActiveStepStatus: (status:ModuleStepStatus):boolean => false,
    resetStepsStatus: () => {},
    totalSteps: 0,
    isLastStep: false,
    isCompleted: ():boolean => false,

    save: async () => { },

    isLoading: false,
    setIsLoading: (isLoading:boolean) => {},
    
}


export const imuUtils = {

    getBuildingTypeRevaluation: (categories:any, id:string) => {
        var result = null;
        categories.forEach((e:any) => {
            if(e.value === id) result = e.revaluation;
        });
        return result;
    },
    getBuildingTypeMultiplier: (categories:any, id:string) => {
        var result = null;
        categories.forEach((e:any) => {
            if(e.value === id) result = e.multiplier;
        });
        return result;
    },
    getBuildingTypeDetraction: (categories:any, id:string) => {
        var result = null;
        categories.forEach((e:any) => {
            if(e.value === id) result = e.detraction;
        });
        return result;
    },
    getRate: (categories:any, id:string) => {
        var result = null;
        categories.forEach((e:any) => {
            if(e.value === id) result = e.rate;
        });
        console.log("rate: " + result);
        return result;
    },

    getSubTotal1: (categories:any, income:number, typeId:string ) => {
    
        var value:number = income;
        value *= ((100 + imuUtils.getBuildingTypeRevaluation(categories, typeId)!) / 100);
    
        return value;
    },
    getSubTotal2: (categories:any, income:number, typeId:string) => {
    
        var value:number = imuUtils.getSubTotal1(categories, income, typeId);
        value *= imuUtils.getBuildingTypeMultiplier(categories, typeId)!;
        return value;
    
    
    },
    getSubTotal3: (categories:any, income:number, ownership:number, time:number, typeId:string) => {
    
        var value:number = imuUtils.getSubTotal2(categories, income, typeId);
        value = (value * ownership / 100);
        value = (value * time / 12);
    
        return value;
    
    
    },
    getSubTotal4: (categories:any, income:number, ownership:number, time:number, typeId:string) => {
    
        var value:number = imuUtils.getSubTotal3(categories, income, ownership, time, typeId);
        value *= imuUtils.getRate(categories, typeId)! / 1000;
    
        return value;
    
    
    },
    getTotal: (categories:any, income:number, ownership:number, time:number, typeId:string) => {
    
        var value:number = imuUtils.getSubTotal4(categories, income, ownership, time, typeId);
        value -= imuUtils.getBuildingTypeDetraction(categories, typeId)!;
    
        if(value < 0) value = 0;

        return value;
    
    
    },
}